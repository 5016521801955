<footer class="main-footer">
  <div class="container">
    <div class="inner-wrapper-footer">
      <ul class="footer-menu-left">
        <li class="menu-item"><a href="https://www.trivitaclinic.com/" target="_blank">Brought <br> to you by</a></li>
        <li class="divider"></li>
        <li class="menu-item">
          <a href="https://www.trivitaclinic.com/" target="_blank">
            <img src="../../../../../assets/images/logo-footer.png" alt="trivita clinic logo">
          </a>
        </li>
      </ul>
      <ul class="footer-menu-right">
        <li><a [routerLink]="['/privacy-policy']">Privacy policy</a></li>
        <li><a [routerLink]="['/terms-of-use']">Terms of use</a></li>
      </ul>
    </div>
  </div>
</footer>