import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class ModalJQueryService {
  public initModalJQuery(): void {
    $('.modal-trigger').click(function (e) {
      e.preventDefault();
      $('.modal').css({ display: 'none' }); //close modal before open another one
      var dataModal = $(this).attr('data-modal');
      $('#' + dataModal).css({ display: 'block' });
    });

    $('.close-modal, .modal-sandbox').click(function () {
      $('.modal').css({ display: 'none' });

      //stop playing video after close modal box
      if ($('.videoWrapper iframe')) {
        $('.videoWrapper iframe').each(function () {
          var src = $(this).attr('src');
          $(this).attr('src', src);
        });
      }
    });
  }
}
