import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorage } from '../../../../helpers/local-storage.decorator';

@Injectable({
  providedIn: 'root',
})
export class StepService {
  @LocalStorage() user;

  constructor(private http: HttpClient) {}

  public getStep({ PartNumber, StepNumber, EvaluationID }): Observable<any> {
    return this.http.post(
      'Step/Get',
      JSON.stringify({
        EvaluationID: EvaluationID ? EvaluationID : this.user.EvaluationID,
        PartNumber,
        StepNumber,
      })
    );
  }

  public saveStep({
    Answers,
    EvaluationID,
    PartNumber,
    StepNumber,
  }): Observable<any> {
    return this.http.post(
      'Step/Save',
      JSON.stringify({
        Answers,
        EvaluationID: EvaluationID ? EvaluationID : this.user.EvaluationID,
        PartNumber,
        StepNumber,
      })
    );
  }

  public getStepResult(EvaluationID: string): Observable<any> {
    return this.http.post(
      'Step/Result',
      JSON.stringify({
        EvaluationID: EvaluationID ? EvaluationID : this.user.EvaluationID,
        CustomerId: this.user.CustID,
      })
    );
  }

  public get allStepsResults$(): Observable<any> {
    return this.http.post(
      'Step/Result',
      JSON.stringify({
        CustomerId: this.user.CustID,
      })
    );
  }
}
