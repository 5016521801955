import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  private spinnerSubject = new Subject<boolean>();
  isShowSpinner$ = this.spinnerSubject.asObservable();

  show(){
    this.spinnerSubject.next(true);
  }
  hide(){
    this.spinnerSubject.next(false);
  }
}
