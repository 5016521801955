import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const BaseUrlInterceptorFactory = (config) => {
  return new BaseUrlInterceptor(config);
};

export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(private configService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url && this.configService?.config) {
      const url = `${this.configService.config.baseURL}${req.url}`;
      const Req = req.clone({url});
      return next.handle(Req);
    }

    return next.handle(req);
  }
}
