import { createAction, props } from '@ngrx/store';

export const loadStepApi = createAction(
  '[LOAD STEP API] Step API',
  props<{ PartNumber: number; StepNumber: number; EvaluationID: number }>()
);

export const saveStepApi = createAction(
  '[SAVE STEP API] Step API',
  props<any>()
);

export const saveStep = createAction('[SAVE STEP] Step', props<any>());

export const clearStep = createAction('[CLEAR STEP] Step');
