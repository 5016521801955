<div class="row" *ngIf="config">
  <div class="col-12">
    <div class="steps-wrapper" [class.bottomNav]="!progress">
      <button [style.zIndex]="3" type="button" class="button-labeled left"
        (click)="config.prevLink ? navigatePrev.emit(evaluationID ? config.prevLink + '?id=' + evaluationID : config.prevLink): ''">
        <i class="icon icon-arrow-left-solid"></i>
        Back
      </button>

      <div *ngIf="progress" class="pro-version">
        <div class="parts-wrapper">
          <div class="parts">
            <div *ngFor="let part of parts; let i = index; let last = last" class="part">
              <div class="part__circle"
                [class.part__circle--active]="i <= config.part || (i === (config.part + 1) && config.totalSteps === config.step)">
              </div>
              <div class="part__progress-line" [class.part__progress-line--active]="i < config.part">
              </div>
              <div *ngIf="i == config.part" class="part__step"
                [style.width]="100 / config.totalSteps * config.step + '%'">
                <div class="part__progress-circle" *ngIf="config.totalSteps > config.step">
                  <div class="part__inner-circle"></div>
                </div>
              </div>
              {{part.name}}
              <div *ngIf="last" class="part__finish">
                <div class="part__circle" [class.part__circle--active]="config.finish"></div>
                FINISH
              </div>
            </div>
          </div>
        </div>
      </div>

      <button [style.zIndex]="3" class="button-labeled accent right"
        (click)="config.nextLink ? navigateNext.emit(evaluationID ? config.nextLink + '?id=' + evaluationID : config.nextLink): ''" [disabled]="form?.invalid ? true : null"
        [class.disabled-next-button]="form?.invalid">
        Next
        <i class="icon icon-arrow-right-solid"></i>
      </button>
    </div>
  </div>
</div>