import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { countriesCodes } from '../../../../enums/countries-codes.enum';

@Injectable({
  providedIn: 'root',
})
export class IpServiceService {
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  private get ipAddress$(): Observable<any> {
    return this.httpClient.get('https://api.ipify.org/?format=json');
  }

  private getCountryByIp(ip: string): Observable<any> {
    return this.httpClient.get(
      `https://api.trivita.com/smart/api/Utilities/GetCountryFromIP?ip=${ip}`
    );
  }

  public get fetchCountryId$(): Observable<1 | 2> {
    return this.ipAddress$.pipe(
      map(({ ip }) => ip),
      filter(Boolean),
      switchMap((ip) => this.getCountryByIp(ip as string)),
      map(({ countryCode }) => countryCode),
      filter(Boolean),
      map((countryCode) =>
        countriesCodes[countryCode as string]
          ? countriesCodes[countryCode as string]
          : 1
      )
    );
  }
}
