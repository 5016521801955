import { Injectable } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import {
  loadStepApi,
  saveStep,
  saveStepApi,
  clearStep,
} from '../actions/step.action';

@Injectable({
  providedIn: 'root',
})
export class StepFacade {
  constructor(private store: Store<{ step: any }>) {}

  public step$ = this.store.pipe(select('step'));

  public set loadStepApi(payload) {
    this.store.dispatch(loadStepApi(payload));
  }

  public saveStep(payload): Action {
    return saveStep(payload);
  }

  public set saveStepApi(payload) {
    this.store.dispatch(saveStepApi(payload));
  }

  public clearStep() {
    this.store.dispatch(clearStep());
  }
}
