import { Directive, Renderer2, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appGaugeStyles]',
})
export class GaugeStylesDirective implements OnInit {
  @Input('value') value: number;
  @Input('min') min: number;
  @Input('max') max: number;

  constructor(private renderer: Renderer2, private elmRef: ElementRef) {}

  ngOnInit(): void {
    const newGaugeValue = Math.floor(
      ((+this.value - this.min) / (this.max - this.min)) * 100
    );

    this.renderer.setStyle(
      this.elmRef.nativeElement,
      '--gauge-value',
      `${newGaugeValue}`,
      2
    );

    this.renderer.setStyle(
      this.elmRef.nativeElement,
      '--gauge-display-value',
      `${this.value}`,
      2
    );
  }
}
