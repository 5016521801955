import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/shared/components/login/login.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'start',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/assessment/assessment.module').then(
        (m) => m.AssessmentModule
      ),
  },
  {
    path: 'part-2',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/signs-symptoms/signs-symptoms.module').then(
        (m) => m.SignsSymptomsModule
      ),
  },
  {
    path: 'part-3',
    loadChildren: () =>
      import('./modules/bio-markers/bio-markers.module').then(
        (m) => m.BioMarkersModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./modules/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./modules/terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUseModule
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
