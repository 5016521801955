import { Action, createReducer, on } from '@ngrx/store';
import * as stepActions from '../actions/step.action';

export const initialState = {};

const stepReducer = createReducer(
  initialState,
  on(stepActions.saveStep, (state, payload) => ({ ...state, ...payload })),
  on(stepActions.clearStep, (state, payload) => ({}))
);

export function reducer(state, action: Action) {
  return stepReducer(state, action);
}
