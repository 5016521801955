

<header class="main-header">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="header-wrapper">
          <a href="https://www.trivitaclinic.com/" target="_blank" class="logo">
            <img src="../../../../../assets/images/logo.png" alt="logo">
          </a>
          <div class="login-menu">
            <div class="user-info" *ngIf="user?.token; else login" (click)="show = !show">
              Welcome {{user?.firstname}}
              <app-header-dropdown [(show)]="show" (click)="show = !show" (logout)="logOut()"></app-header-dropdown>
            </div>
            <ng-template #login>
              <button class="btn-transparent btn-login" (click)="loginBtnSubject.next()" [disabled]="!authService.sso">Log in</button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
