import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationConfig } from './navigation-base.class';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

const PARTS_CONFIG: { name: string }[] = [
  { name: 'START' },
  { name: 'PART 1' },
  { name: 'PART 2' },
];

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() progress = true;
  @Input() form: FormGroup;
  @Input() config: NavigationConfig;
  @Output() navigateNext: EventEmitter<string> = new EventEmitter();
  @Output() navigatePrev: EventEmitter<string> = new EventEmitter();

  public parts = PARTS_CONFIG;
  public evaluationID: string;

  constructor(protected route: ActivatedRoute) {
    this.route.queryParams
      .pipe(
        filter((params) => params.id),
        map((params) => params.id)
      )
      .subscribe((id) => {
        this.evaluationID = id;
      });
  }
}
