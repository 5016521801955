import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AuthService {
  private _loginChangedSubject = new Subject<any>();
  public loginChanged$ = this._loginChangedSubject.asObservable();

  private _ssoLoadingSubject = new BehaviorSubject<boolean>(true);
  public isSSOLoading = this._ssoLoadingSubject.asObservable();

  public sso: any;
  public sessionId: string;
  public user: any;

  constructor(private router: Router) {
    this.startListenToEvents();
  }

  startListenToEvents() {
    window.addEventListener('sso:ready', (event) => {
      this.sso = window['sso'];
      console.log(this.sso);
      this.sessionId = this.sso.session;
      console.log(this.sessionId);
      this._ssoLoadingSubject.next(false);
      if (this.sso) {
        window.addEventListener('sso:login', (event) => {
          const user = this.sso.profile;
          const token = this.sso.token;

          this.user = { ...user, token };
          console.log(this.user);
          localStorage.setItem('user', JSON.stringify(this.user));

          this._loginChangedSubject.next(this.user);

          this.closeLoginModal();
        });

        window.addEventListener('sso:logout', () => {
          this.user = null;
          this._loginChangedSubject.next(null);
          localStorage.clear();
          this.router.navigate(['/']);
        });

        window.addEventListener('session:timeout', () => {
          console.log('Session timeout! Loging out');
          this.logOut();
        });
      }
    });
  }

  openLoginModal(redirectURL?) {
    this.sso.openLoginModal(redirectURL);
  }

  closeLoginModal() {
    this.sso.closeLoginModal();
  }

  logOut() {
    this.sso?.logout();
    localStorage.clear();
    localStorage.removeItem('user');
    this.router.navigate(['/']);
  }

  isLoggedIn() {
    return this.sso?.isLoggedIn;
  }

  resetPassword() {
    this.sso.resetPassword();
  }
}
