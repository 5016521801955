<app-header></app-header>

<main class="main page-login">
  <div class="container">
    <div class="row no-gutters main-block-home-page">

      <div class="col-md-6 col-sm-12">
        <div class="video-to-modal home-page-video mobile-hide">
          <a href="#" class="inner-video-btn modal-trigger" data-modal="modal-video">
            <span class="icon-play-video"><span class="path1"></span><span class="path2"></span></span>
          </a>
        </div>
      </div>

      <div class="col-md-6 col-sm-12">
        <div class="inner-wrapper">
          <header class="header-content">
            <h2 class="title-content">Start Now</h2>
          </header>

          <div class="inner-content">
            <div class="video-to-modal video-to-modal--login-mobile rounded mobile-visible">
              <a href="#" class="inner-video-btn modal-trigger" data-modal="modal-video">
                <span class="icon-play-video"><span class="path1"></span><span class="path2"></span></span>
              </a>
            </div>

            <div [formGroup]="form">
              <label class="label-input" for="first_name">First name</label>
              <div class="custom-input">
                <input formControlName="firstName" type="text" autocomplete="off" name="first_name" id="first_name">
              </div>

              <label class="label-input" for="last_name">Last name</label>
              <div class="custom-input">
                <input formControlName="lastName" type="text" name="last_name" autocomplete="off" id="last_name">
              </div>

              <label class="label-input" for="email_new">Email</label>
              <div class="custom-input">
                <input formControlName="email" type="email" name="email_new" id="email_new">
              </div>

              <label class="label-input" for="password">Password</label>
              <div class="custom-input" style="margin-bottom: 50px">
                <input formControlName="password" type="password" name="password" id="password">
              </div>

              <button [disabled]="!form.valid || form.pristine || isFormSubmited" class="btn-wide primary" (click)="signUp()">Let's
                Go!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal video -->
  <div class="modal" id="modal-video">
    <div class="modal-sandbox"></div>
    <div class="modal-box">
      <div class="modal-body modal-video">
        <div class="col-1-2">
          <div class="videoWrapper">
            <iframe src="https://player.vimeo.com/video/467887910?title=0&byline=0&portrait=0" width="640" height="360"
              frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div>
        </div>

        <div class="close-modal" id="close-btn">
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>

</main>

<!-- Email already exists modal -->
<div class="ih-modal" [style.display]="showEmailExistsModal ? 'block': 'none'">
  <div class="modal-sandbox"></div>
  <div class="modal-box">
    <div class="modal-body text-center">
      Email already exists please login with your TriVita account
    </div>
  </div>
</div>

<app-footer></app-footer>