import { SpinnerService } from './../../services/spinner.service';
import { AuthService } from './../../../../core/services/auth.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';

import { LocalStorage } from '../../../../helpers/local-storage.decorator';
import { IpServiceService } from '../../services/api/ip-service.service';
import { ConfigService } from '../../services/config.service';
import { CustomerService } from '../../services/api/customer.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @LocalStorage() user;
  public show = false;
  public showLoginModal = false;
  public showSpinnerModal = false;

  loginBtnSubject = new Subject();

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private customerService: CustomerService,
    private configService: ConfigService,
    private ipService: IpServiceService,
    public authService: AuthService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.loginBtnSubject.subscribe(() => {
      this.authService.openLoginModal();
    });

    this.authService.loginChanged$.subscribe((user) => {
      if (user && user.token) {
        this.handleGotUserData(this.user);
      }
    });
  }

  private handleGotUserData(user: any): void {
    if (user?.memberId) {
      this.spinnerService.show();
      combineLatest([
        this.customerService.signUp({ email: user.email }),
        this.ipService.fetchCountryId$,
      ])
        .pipe(
          finalize(() => {
            this.spinnerService.hide();
          })
        )
        .subscribe(([result, countryID]) => {
          this.user = {
            ...result,
            ...user,
            token: user.token,
            countryID: countryID,
          };
          this.showLoginModal = false;
          if (
            this.user.NumberOfEvaluations > 1 ||
            this.user.DateCompleted !== ''
          ) {
            this.router.navigate(['/part-3/step/final-results']);
          } else {
            this.router.navigate(['/start']);
          }
        });
    }
  }

  public logOut(): void {
    this.authService.logOut();
  }

  login(): void {
    this.authService.openLoginModal();
  }
}
