import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'summaryColor',
})
export class SummaryColorPipe implements PipeTransform {
  transform({ Score, Ranges }): string {
    return Ranges.filter(
      ({ MaxValue, MinValue }) => MaxValue >= Score && Score >= MinValue
    )[0]?.Color;
  }
}
