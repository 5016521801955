import { AuthService } from './../services/auth.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LocalStorage } from '../../helpers/local-storage.decorator';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  @LocalStorage() user;

  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let customReq = null;
    const token = this.user ? this.user.token : null;

    if (token) {
      customReq = request.clone({
        headers: request.headers
          .append('Authorization', `bearer ${token}`)
          .append('Accept', 'application/json')
          .append('Content-Type', 'application/json'),
      });
    }

    return next.handle(customReq || request).pipe(
      catchError((error: Response) => {
        if (error.status === 500) {
          // this.router.navigate(['/login']);
        }
        return observableThrowError(error);
      })
    );
  }
}
