import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigService {
  private config;
  private privacyPolicy;
  private termsOfUse;

  constructor(private http: HttpClient) {}

  public getConfig(name: 'config' | 'privacyPolicy' | 'termsOfUse'): any {
    return this[name];
  }

  public load(
    url: string,
    name: 'config' | 'privacyPolicy' | 'termsOfUse'
  ): Promise<any> {
    return new Promise<void>((resolve) => {
      this.http.get(url).subscribe(
        (config) => {
          this[name] = config;
          resolve();
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }
}
