import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GaugeStylesDirective } from '../../directives/gauge-styles/gauge-styles.directive';
import { SafePipe } from '../../modules/shared/pipes/safe.pipe';
import { SummaryColorPipe } from '../../modules/shared/pipes/summary-color.pipe';
import { HeaderDropdownComponent } from './components/header/header-dropdown/header-dropdown.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const COMPONENTS = [
  NavigationComponent,
  HeaderComponent,
  FooterComponent,
  LoginComponent,
  GaugeStylesDirective,
  SafePipe,
  HeaderDropdownComponent,
  SummaryColorPipe,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    MatProgressSpinnerModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class SharedModule {}
