import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { StepService } from '../../modules/shared/services/api/step.service';

import * as stepActions from '../actions/step.action';
import { StepFacade } from '../facades/step.facade';

@Injectable()
export class StepEffects {
  loadStep$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(stepActions.loadStepApi),
        mergeMap(({ PartNumber, StepNumber, EvaluationID  }) =>
          this.stepService.getStep({
            PartNumber,
            StepNumber,
            EvaluationID
          })
        )
      )
      .pipe(
        map(
          (step) => this.stepFacade.saveStep(step),
          catchError(() => EMPTY)
        )
      )
  );

  constructor(
    private actions$: Actions,
    private stepService: StepService,
    private stepFacade: StepFacade
  ) {}
}
