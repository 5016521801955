import { TrivitaSessionInterceptor } from './core/interceptors/trivitasession.interceptor';
import { AuthService } from './core/services/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './redux/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';

import { StepEffects } from './redux/effects/step.effect';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigService } from './modules/shared/services/config.service';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { BaseUrlInterceptorFactory } from './core/interceptors/base-url.interceptor';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { RetryInterceptor } from './core/interceptors/retry.interceptor';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([StepEffects]),
    BrowserAnimationsModule,
    MatProgressSpinnerModule
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: PrivacyPolicyLoader,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: TermsOfUse,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TrivitaSessionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: BaseUrlInterceptorFactory,
      deps: [ConfigService],
      multi: true,
    },
  ],
})
export class AppModule {}

export function ConfigLoader(configService): () => string {
  // this factory need to return a function (that return a promise)
  return () => configService.load(environment.configFile, 'config');
}

export function PrivacyPolicyLoader(configService): () => string {
  return () => configService.load(environment.privacyPolicy, 'privacyPolicy');
}

export function TermsOfUse(configService): () => string {
  return () => configService.load(environment.termsOfUse, 'termsOfUse');
}
