import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../../environments/environment';

import * as getStep from '../reducers/step.reducer';

export interface State {
  step: any;
}

export const reducers: ActionReducerMap<State> = {
  step: getStep.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];
